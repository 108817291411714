import { detect } from 'detect-browser'

export const browser = detect()

const LITE_WALLET_URL = 'https://wallet.vechain.energy/'

export default function openLiteWallet (url) {
  const options = (() => {
    switch (browser && browser.os) {
      case 'iOS':
      case 'android':
      case 'Android OS':
        return {}
      default:
        return {
          target: `sync|${window.location.host}`,
          features: 'width=360,height=640,resizable,scrollbars=yes,dependent,modal'
        }
    }
  })()

  return window.open(
    new URL(`#/${url}`, LITE_WALLET_URL).href,
    options.target,
    options.features)
}
