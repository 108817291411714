import React from 'react'
import ReactDOM from 'react-dom/client'
import 'antd/dist/antd.variable.css'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ConfigProvider } from 'antd'

ConfigProvider.config({
  theme: {
    primaryColor: '#F15421',
    warningColor: '#F68A1E',
    errorColor: '#ED1D1D'
  }
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <ConfigProvider>
      <App />
    </ConfigProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
